<template>
  <v-app>
    <div>
      <b-form @submit.stop.prevent="formOnsubmit">
        <!-- Input Date -->
        <!-- <b-form-group
          id="input-group-date"
          label="Tanggal:"
          label-for="input-date"
        >
          <v-dialog
            ref="dialog"
            v-model="modal2"
            :return-value.sync="form.date"
            persistent
            width="350px"
          >
            <template v-slot:activator="{ on, attrs }">
              <b-form-input
                readonly
                v-bind="attrs"
                v-on="on"
                id="input-trigger-modal-date"
                v-model="form.date"
              >
              </b-form-input>
            </template>

            <v-date-picker v-if="modal2" locale="id" v-model="form.date">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal2 = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(form.date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <small class="text-danger">{{ error.date }}</small>
        </b-form-group> -->

        <b-form-group
          id="input-group-birth-date"
          label="Tanggal :"
          label-for="input-sip-birth-date"
        >
          <b-input-group>
            <div class="row">
              <div class="col-md-12">
                <v-dialog
                  ref="dialog"
                  v-model="modal2"
                  :return-value.sync="form.date"
                  persistent
                  width="350px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <b-input-group>
                      <b-input-group-prepend>
                        <button
                          class="btn btn-secondary"
                          type="button"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <i class="fas fa-calendar"></i>
                        </button>
                      </b-input-group-prepend>
                      <b-form-input
                        id="input-trigger-modal-patient"
                        v-model="form.date"
                        placeholder="(YYYY-MM-DD)"
                        @input="formaterDate"
                      >
                      </b-form-input>
                    </b-input-group>
                  </template>

                  <v-date-picker v-if="modal2" v-model="form.date" locale="id">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal2 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(form.date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </div>
            </div>
          </b-input-group>

          <small class="text-danger">{{ error.date }}</small>
        </b-form-group>

        <!-- Input Modal Trigger -->
        <b-form-group
          id="input-group-trigger-modal-patient"
          label="Pasien:"
          label-for="input-trigger-modal-patient"
        >
          <b-form-input
            id="input-trigger-modal-patient"
            v-model="display.patient_name"
            placeholder="Pilih Pasien"
            readonly
            @click="$bvModal.show('modal-patient')"
          >
          </b-form-input>
          <small class="text-danger">{{ error.patient_id }}</small>
        </b-form-group>

        <div class="bg-light" v-if="items.length > 0">
        <b-table
          striped
          hover
          class="mt-3"
          :items="items"
          :fields="fields"
          responsive
        >
          <!-- <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.key === 'actions' ? '15%' : '' }"
            />
          </template> -->
          <template #cell(operator)="data">
            <ul
              v-for="operator in data.item.operator_display"
              :key="operator.id"
            >
              <li >{{ operator.name }}</li>
            </ul>
            <!-- <span v-if="data.item.operator_display.length < 1">-</span> -->
          </template>
          <template #cell(assistant)="data">
            <ul
              v-for="assistant in data.item.assistant_display"
              :key="assistant.id"
            >
              <li >
                {{ assistant.name + "-" + assistant.position }}
              </li>
            </ul>
            <!-- <span v-if="data.item.assistant_display.length < 1">-</span> -->
          </template>
          <template #cell(action_type)="data">
            <!-- {{ displayActionType(data.item.action_type_display) }} -->
            <ul
              v-for="action_type in data.item.action_type_display"
              :key="action_type.id"
            >
              <li >{{ action_type.name }}</li>
            </ul>
            <!-- <span v-if="data.item.operator_display.length < 1">-</span> -->
          </template>
          <template #cell(anesthetist)="data">
            <!-- {{ displayAnesthetist(data.item.anesthetist_display) }} -->
            <ul
              v-for="anesthetist in data.item.anesthetist_display"
              :key="anesthetist.id"
            >
              <li >{{ anesthetist.name }}</li>
            </ul>
            <!-- <span v-if="data.item.anesthetist_display.length < 1">-</span> -->
          </template>
          <template #cell(medicines)="data">
            <!-- {{ displayAnesthetist(data.item.anesthetist_display) }} -->
            <ul
              v-for="medicines in data.item.medicines_display"
              :key="medicines.id"
            >
              <li >
                {{
                  medicines.name +
                  ":" +
                  medicines.quantity +
                  `(${medicines.total_price})`
                }}
              </li>
            </ul>
            <!-- <span v-if="data.item.anesthetist_display.length < 1">-</span> -->
          </template>
        </b-table>
        <b-pagination
          v-if="items.length > 10"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="mt-4"
        ></b-pagination>
        </div>

        <!-- Input  Operator-->
        <b-form-group id="input-group-operator">
          <label for="input-operator"
            >Operator: <em class="text-muted">opsional</em></label
          >
          <treeselect
            @select="setOperator"
            @deselect="removeOperator"
            v-model="operator"
            :multiple="true"
            placeholder="Pilih Operator"
            :options="doctors"
          />
          <small class="text-danger">{{ error.operator }}</small>
        </b-form-group>
        
        <!-- Input  Operator-->
        <b-form-group id="input-group-operator">
          <label for="input-operator"
            >Dokter Penanggung jawab: <em class="text-muted">opsional</em></label
          >
          <treeselect
            @select="setResponsibleDoctor"
            @deselect="removeResponsibleDoctor"
            v-model="responsibleDoctor"
            :multiple="true"
            placeholder="Pilih Dokter Penanggung Jawab"
            :options="doctors"
          />
          <small class="text-danger">{{ error.responsible_doctor }}</small>
        </b-form-group>

        <!-- Input Modal Trigger -->
        <b-form-group id="input-group-trigger-modal-assistant">
          <label for="input-trigger-modal-assistant"
            >Asisten Kamar Operasi: <em class="text-muted">opsional</em></label
          >
          <b-input-group>
            <b-form-input
              id="input-trigger-modal-assistant"
              v-model="display.assistant_name"
              placeholder="Pilih Asisten"
              readonly
              @click="$bvModal.show('modal-nurse')"
            >
            </b-form-input>
            <template #append>
              <b-input-group-text
                squared
                :class="btnClearMedicineClass"
                style="border-left: none"
                @click="resetAssistant"
                @mouseover="btnClearMedicineClass = 'bg-light-danger'"
                @mouseout="btnClearMedicineClass = 'bg-white'"
              >
                <i class="fas fa-sm fa-times text-danger"></i>
              </b-input-group-text>
            </template>
          </b-input-group>
          <small class="text-danger">{{ error.assistant }}</small>
        </b-form-group>
        
        <b-form-group id="input-group-trigger-modal-assistant">
          <label for="input-trigger-modal-assistant"
            >Omploop: <em class="text-muted">opsional</em></label
          >
          <b-input-group>
            <b-form-input
              id="input-trigger-modal-assistant"
              v-model="display.omploop_name"
              placeholder="Pilih Omploop"
              readonly
              @click="$bvModal.show('modal-omploop')"
            >
            </b-form-input>
            <template #append>
              <b-input-group-text
                squared
                :class="btnClearMedicineClass"
                style="border-left: none"
                @click="resetOmploop"
                @mouseover="btnClearMedicineClass = 'bg-light-danger'"
                @mouseout="btnClearMedicineClass = 'bg-white'"
              >
                <i class="fas fa-sm fa-times text-danger"></i>
              </b-input-group-text>
            </template>
          </b-input-group>
          <small class="text-danger">{{ error.omploop }}</small>
        </b-form-group>

        <b-form-group id="input-group-trigger-modal-assistant">
          <label for="input-trigger-modal-assistant"
            >Perawat: <em class="text-muted">opsional</em></label
          >
          <b-input-group>
            <b-form-input
              id="input-trigger-modal-assistant"
              v-model="display.nurse_action_name"
              placeholder="Pilih Perawat"
              readonly
              @click="$bvModal.show('modal-nurse-action')"
            >
            </b-form-input>
            <template #append>
              <b-input-group-text
                squared
                :class="btnClearMedicineClass"
                style="border-left: none"
                @click="resetNurseAction"
                @mouseover="btnClearMedicineClass = 'bg-light-danger'"
                @mouseout="btnClearMedicineClass = 'bg-white'"
              >
                <i class="fas fa-sm fa-times text-danger"></i>
              </b-input-group-text>
            </template>
          </b-input-group>
          <small class="text-danger">{{ error.nurse_action }}</small>
        </b-form-group>

        <!-- Description input -->
        <b-form-group id="input-group-nurse-notes">
          <label for="input-nurse-notes"
            >Catatan Perawat: <em class="text-muted">opsional</em></label
          >
          <b-form-textarea
            id="input-nurse-notes"
            v-model="form.nurse_notes"
            placeholder="Catatan Perawat"
            rows="4"
            max-rows="8"
          ></b-form-textarea>
          <small class="text-danger">{{ error.nurse_notes }}</small>
        </b-form-group>

        <!-- Input  Action Type-->
        <b-form-group
          id="input-group-action-type"
          label="Jenis Tindakan:"
          label-for="input-action-type"
        >
          <treeselect
            v-model="action_type"
            @select="setActionType"
            @deselect="removeActionType"
            :multiple="true"
            required
            placeholder="Jenis Tindakan"
            :options="action_types"
          />
          <small class="text-danger">{{ error.action_type }}</small>
        </b-form-group>

        <!-- Input Anesthesia -->
        <!-- <b-form-group
          id="input-group-anesteshia"
          label="Anestesi:"
          label-for="input-anesteshia"
        >
          <b-form-select
            id="input-anesteshia"
            v-model="form.anesteshia"
            :options="anesteshia"
          ></b-form-select>
          <small class="text-danger">{{ error.anesteshia }}</small>
        </b-form-group> -->
        <b-form-group v-slot="{ ariaDescribedby }">
          <label for="radio-group-1"
            >Anestesi: <em class="text-muted">opsional</em></label
          >
          <b-form-radio-group
            id="radio-group-1"
            v-model="form.anesteshia"
            :options="anesteshia"
            :aria-describedby="ariaDescribedby"
            name="radio-options"
          ></b-form-radio-group>
        </b-form-group>

        <!-- Name input -->
        <b-form-group id="input-group-anesthetist">
          <label for="input-anesthetist"
            >Dokter Anastesi: <em class="text-muted">opsional</em></label
          >
          <treeselect
            @select="setAnesthetist"
            @deselect="removeAnesthetist"
            v-model="anesthetist"
            :multiple="true"
            :options="doctors"
          />
          <small class="text-danger">{{ error.anesthetist }}</small>
        </b-form-group>

        <div class="row">
          <div class="col-md-12">
            <span class="font-weight-bold">Obat dan Alkes :</span>
          </div>
        </div>

        <!-- Input Modal Trigger -->
        <b-form-group id="input-group-trigger-modal-medicine">
          <label for="input-trigger-modal-medicine"
            >Obat dan Alkes Digunakan: <em class="text-muted">opsional</em></label
          >
          <b-input-group>
            <b-form-input
              id="input-trigger-modal-medicine"
              v-model="display.medicine_name"
              placeholder="Pilih Obat dan Alkes"
              readonly
              @click="$bvModal.show('modal-medicine')"
              style="border-right: none"
            >
            </b-form-input>
            <template #append>
              <b-input-group-text
                squared
                :class="btnClearMedicineClass"
                style="border-left: none"
                @click="resetMedicine"
                @mouseover="btnClearMedicineClass = 'bg-light-danger'"
                @mouseout="btnClearMedicineClass = 'bg-white'"
              >
                <i class="fas fa-sm fa-times text-danger"></i>
              </b-input-group-text>
            </template>
          </b-input-group>
          <small class="text-danger">{{ error.medicines }}</small>
        </b-form-group>

        <div class="overflow-auto">
          <div class="bg-light" v-if="coll_medicine.length > 0">
            <table class="table" v-if="coll_medicine.length > 0">
              <tr>
                <th>Nama</th>
                <th>Kuantitas</th>
                <th>Harga Jual</th>
                <th>Total Harga</th>
                <th>Aksi</th>
              </tr>
              <tr v-for="mdc in coll_medicine" :key="mdc.id">
                <td>{{ mdc.name }}</td>
                <td>{{ mdc.quantity }}</td>
                <td>{{ mdc.sales_price }}</td>
                <td>{{ mdc.total_price }}</td>
                <td>
                  <b-button
                    size="sm"
                    class="btn-danger"
                    v-b-tooltip.hover.left
                    title="Hapus Obat dan Alkes"
                    type="button"
                    @click="removeMedicine(mdc.id)"
                    ><i class="fas fa-trash px-0"></i
                  ></b-button>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <!-- Input Modal Trigger -->
        <b-form-group id="input-group-trigger-modal-medicine">
          <label for="input-trigger-modal-medicine"
            >Obat dan Alkes Dibawa Pulang: <em class="text-muted">opsional</em></label
          >
          <b-input-group>
            <b-form-input
              id="input-trigger-modal-medicine"
              v-model="display.medicine_order"
              placeholder="Pilih Obat dan Alkes"
              readonly
              @click="$bvModal.show('modal-medicine-order')"
              style="border-right: none"
            >
            </b-form-input>
            <template #append>
              <b-input-group-text
                squared
                :class="btnClearMedicineClass"
                style="border-left: none"
                @click="resetMedicineTakeaway"
                @mouseover="btnClearMedicineClass = 'bg-light-danger'"
                @mouseout="btnClearMedicineClass = 'bg-white'"
              >
                <i class="fas fa-sm fa-times text-danger"></i>
              </b-input-group-text>
            </template>
          </b-input-group>
          <small class="text-danger">{{ error.medicine_order }}</small>
        </b-form-group>

        <div class="overflow-auto">
          <div class="bg-light" v-if="coll_medicine_order.length > 0">
            <table class="table" v-if="coll_medicine_order.length > 0">
              <tr>
                <th>Nama</th>
                <th>Kuantitas</th>
                <th>Harga Jual</th>
                <th>Total Harga</th>
                <th>Aksi</th>
              </tr>
              <tr v-for="mdco in coll_medicine_order" :key="mdco.id">
                <td>{{ mdco.name }}</td>
                <td>{{ mdco.quantity }}</td>
                <td>{{ mdco.sales_price }}</td>
                <td>{{ mdco.total_price }}</td>
                <td>
                  <b-button
                    size="sm"
                    class="btn-danger"
                    v-b-tooltip.hover.left
                    title="Hapus Obat dan Alkes"
                    type="button"
                    @click="removeMedicineTakeaway(mdco.id)"
                    ><i class="fas fa-trash px-0"></i
                  ></b-button>
                </td>
              </tr>
            </table>
          </div>
        </div>

         <div class="row">
          <div class="col-md-12">
            <span class="font-weight-bold">Inventori :</span>
          </div>
        </div>

        <!-- Input Modal Trigger -->
        <b-form-group id="input-group-trigger-modal-medicine">
          <label for="input-trigger-modal-medicine"
            >Inventori: <em class="text-muted">opsional</em></label
          >
          <b-input-group>
            <b-form-input
              id="input-trigger-modal-medicine"
              
              placeholder="Pilih Inventori"
              readonly
              @click="$bvModal.show('modal-inventory-payment-categories')"
              style="border-right: none"
            >
            </b-form-input>
            <template #append>
              <b-input-group-text
                squared
                :class="btnClearMedicineClass"
                style="border-left: none"
                @click="resetMedicineTakeaway"
                @mouseover="btnClearMedicineClass = 'bg-light-danger'"
                @mouseout="btnClearMedicineClass = 'bg-white'"
              >
                <i class="fas fa-sm fa-times text-danger"></i>
              </b-input-group-text>
            </template>
          </b-input-group>
          <small class="text-danger">{{ error.medicine_order }}</small>
        </b-form-group>

        <div class="overflow-auto">
          <div class="bg-light" v-if="coll_inventory.length > 0">
            <table class="table" v-if="coll_inventory.length > 0">
              <tr>
                <th>Nama</th>
                <th>Kuantitas</th>
                <!-- <th>Harga Jual</th>
                <th>Total Harga</th> -->
                <th>Aksi</th>
              </tr>
              <tr v-for="mdco in coll_inventory" :key="mdco.id">
                <td>{{ mdco.name }}</td>
                <td>{{ mdco.quantity }}</td>
                <!-- <td>{{ mdco.sales_price }}</td>
                <td>{{ mdco.total_price }}</td> -->
                <td>
                  <b-button
                    size="sm"
                    class="btn-danger"
                    type="button"
                    @click="removeInventory(mdco.id)"
                    ><i class="fas fa-trash px-0"></i
                  ></b-button>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <!-- Attachment -->
        <b-form-group id="input-group-file">
          <label for="input-file"
            >Lampiran: <em class="text-muted">opsional</em></label
          >
          <b-form-file
            multiple
            placeholder="Upload Lampiran"
            drop-placeholder="Drop file here..."
            @change="upload"
          ></b-form-file>
          <small class="text-danger">{{ error.attachment }}</small>
        </b-form-group>

        <!-- Description input -->
        <b-form-group id="input-group-notes">
          <label for="input-notes"
            >Keterangan: <em class="text-muted">opsional</em></label
          >
          <b-form-textarea
            id="input-notes"
            v-model="form.notes"
            placeholder="Keterangan"
            rows="4"
            max-rows="8"
          ></b-form-textarea>
          <small class="text-danger">{{ error.notes }}</small>
        </b-form-group>

        <!-- Submit & Cancel button -->
        <b-button type="submit" variant="primary">Simpan</b-button>
        <b-button
          type="button"
          class="ml-2"
          variant="default"
          @click="$router.push('/medical-actions')"
        >
          Batal
        </b-button>
      </b-form>

      <b-modal
        ref="my-modal"
        hide-footer
        title="Resep Obat"
        @hide="hideModalCancel"
      >
        <b-form @submit.stop.prevent="medicineFormOnsubmit">
          <!-- <div class="d-block text-center"> -->
          <b-form-group
            id="input-group-name"
            label="Nama:"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="coll_medicine_form.name"
              placeholder="Nama obat"
              readonly
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-quantity"
            label="Kuantitas:"
            label-for="input-quantity"
          >
            <b-form-input
              id="input-quantity"
              v-model="coll_medicine_form.quantity"
              placeholder="Kuantitas"
              required
            ></b-form-input>
          </b-form-group>
          <!-- </div> -->
          <!-- Submit & Cancel button -->
          <b-button type="submit" variant="primary">Simpan</b-button>
          <b-button
            type="button"
            class="ml-2"
            variant="default"
            @click="hideModalCancel"
          >
            Cancel
          </b-button>
        </b-form>
      </b-modal>

      <b-modal
        ref="my-modal-takeaway"
        hide-footer
        title="Resep Obat"
        @hide="hideModalCancel"
      >
        <b-form @submit.stop.prevent="medicineOrderFormOnsubmit">
          <!-- <div class="d-block text-center"> -->
          <b-form-group
            id="input-group-name"
            label="Nama:"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="coll_medicine_order_form.name"
              placeholder="Nama obat"
              readonly
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-quantity"
            label="Kuantitas:"
            label-for="input-quantity"
          >
            <b-form-input
              id="input-quantity"
              v-model="coll_medicine_order_form.quantity"
              placeholder="Kuantitas"
              required
            ></b-form-input>
          </b-form-group>
          <!-- </div> -->
          <!-- Submit & Cancel button -->
          <b-button type="submit" variant="primary">Simpan</b-button>
          <b-button
            type="button"
            class="ml-2"
            variant="default"
            @click="hideModalCancel"
          >
            Cancel
          </b-button>
        </b-form>
      </b-modal>

      <!-- modal quantity inventory -->
    <b-modal id="modal-qty-inventory" hide-footer size="md" title="Jumlah Pemakaian">
      <b-form>
        <!-- <div class="d-block text-center"> -->
        <div class="row">
          <div class="col-md-12">
            <b-input-group class="my-2">
              <template #append>
                <b-input-group-text
                  >{{ inventory_form.uom }}</b-input-group-text
                >
              </template>

              <!-- <b-form-group
                    id="input-group-name"
                    label="Nama Item:"
                    label-for="input-name"
                  > -->
              <b-form-input
                id="input-name"
                v-model="inventory_form.quantity"
                placeholder="Kuantitas"
              ></b-form-input>
              <!-- <b-form-input
                id="input-name"
                v-model="medicine_form.quantity"
                placeholder="Nama Item"
                @keyup="inputQty"
              ></b-form-input> -->
              <!-- </b-form-group> -->
            </b-input-group>
            <small class="text-danger">{{ message }}</small>
          </div>
        </div>

        <b-button type="button" @click="saveQtyInventory" variant="primary"
          >Simpan</b-button
        >
        <b-button
          type="button"
          class="ml-2"
          variant="default"
          @click="hideModalCancelInventory"
        >
          Cancel
        </b-button>
      </b-form>
    </b-modal>

      <ModalPatient @chosenPatient="setPatient" @submitedPatient="setPatient" />
      <ModalNurse
        @chosenEmployee="setAssistant"
        :selectedItem="coll_assistant"
        :checkbox="true"
      />
      <ModalOmploop
        @chosenEmployee="setOmploop"
        :selectedItem="coll_omploop"
        :checkbox="true"
      />
      <ModalNurseAction
        @chosenEmployee="setNurseAction"
        :selectedItem="coll_nurse_action"
        :checkbox="true"
      />
      <ModalMedicine
        @chosenMedicine="setPrescription"
        @packageMedicine="setPackagePrescription"
        :selectedItem="coll_medicine"
        :checkbox="true"
        :isSortcut="true"
        :isAbjad="true"
      />
      <ModalMedicine2
        @chosenMedicineOrder="setPrescriptionOrder"
        @packageMedicineOrder="setPackagePrescriptionOrder"
        :selectedItem="coll_medicine_order"
        :checkbox="true"
        :isSortcut="true"
        :isAbjad="true"
      />
      <ModalInventory
      @chosenItem="setInventory"
      :selectedItem="coll_inventory"
      :checkbox="false"
    />
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ModalPatient from "@/component/general/ModalPatient.vue";
import ModalNurse from "@/component/general/ModalNurse.vue";
import ModalNurseAction from "@/component/general/ModalNurseAction.vue";
import ModalOmploop from "@/component/general/ModalOmploop.vue";
import ModalMedicine from "@/component/general/ModalMedicineAction.vue";
import ModalMedicine2 from "@/component/general/ModalMedicine2.vue";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service.js";
import ModalInventory from "@/component/general/ModalInventoryProductCategory.vue";

export default {
  props: {
    form: Object,
    route: String,
  },

  components: {
    ModalPatient,
    ModalNurse,
    ModalNurseAction,
    ModalOmploop,
    ModalMedicine,
    ModalMedicine2,
    ModalInventory,
  },

  data() {
    return {
      modal2: false,
      // Display Data
      display: {
        patient_name: this.$route.query.patient_name ?? "",
        assistant_name: "",
        omploop_name: "",
        nurse_action_name: "",
        medicine_name: "",
        medicine_takeaway: "",
      },
      // Error
      error: {
        patient_id: null,
        patient_name: "",
        patient_age: "",
        electro_laser: "",
        action_type: "",
        operator: "",
        responsible_doctor:"",
        assistant: "",
        omploop: "",
        nurse_action: "",
        anesteshia: "",
        anesthetist: null,
        notes: "",
        date: "",
        medicines: "",
      },
      doctors: [],
      action_types: [],
      operator: [],
      responsibleDoctor: [],
      anesthetist: [],
      action_type: [],
      //   set input
      // Operator
      coll_operator_form: {
        id: "",
        name: "",
      },
      coll_operator: [],
      coll_operator_str: "",
      // responsible doctor
      coll_responsibleDoctor_form: {
        id: "",
        name: "",
      },
      coll_responsibleDoctor: [],
      coll_responsibleDoctor_str: "",
      // Anesthetist
      coll_anesthetist_form: {
        id: "",
        name: "",
      },
      coll_anesthetist: [],
      coll_anesthetist_str: "",
      // Action Type
      coll_action_type_form: {
        id: "",
        name: "",
      },
      coll_action_type: [],
      coll_action_type_str: "",
      // Assistant
      coll_assistant_form: {
        id: "",
        name: "",
        position: "",
      },
      coll_assistant: [],
      coll_assistant_str: "",

      coll_omploop_form: {
        id: "",
        name: "",
        position: "",
      },
      coll_omploop: [],
      coll_omploop_str: "",
      
      // nurse action
      coll_nurse_action_form: {
        id: "",
        name: "",
        position: "",
      },
      coll_nurse_action: [],
      coll_nurse_action_str: "",

      // medicines
      coll_medicine_form: {
        id: "",
        name: "",
        sales_price: "",
        quantity: "",
        total_price: "",
        is_order:false,
      },
      coll_medicine: [],
      coll_medicine_str: "",
      
      // medicine takeaway
      coll_medicine_order_form: {
        id: "",
        name: "",
        sales_price: "",
        quantity: "",
        total_price: "",
        is_order:true,
      },
      coll_medicine_order: [],
      coll_medicine_order_str: "",

      // inventory 
      coll_inventory: [],
      inventory_form: {
        id: "",
        name: "",
        price: 0,
        quantity: null,
        stock: 0,
        uom:"",
      },
      coll_inventory_str: "",

      uploadAttachmentURL: "",
      anesteshia: [
        { text: "Lokal", value: "Lokal" },
        { text: "Sedasi", value: "Sedasi" },
        { text: "Spinal", value: "Spinal" },
        { text: "Umum", value: "Umum" },
      ],
      // data
      formData: new FormData(),
      // other
      message: "",
      btnClearMedicineClass: "bg-white",
      medicine_collection_submit: false,
      medicine_collection_order_submit: false,
      medicine_id: "",
      medicine: "",
      name_medicine: "",
      
      // order medicine
      medicine_order_id: "",
      medicine_order: "",
      name_medicine_order: "",

      // Pagination
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "action_type",
          label: "Diagnosa Tindakan",
          sortable: true,
        },
        {
          key: "operator",
          label: "Operator",
          sortable: true,
        },
        {
          key: "assistant",
          label: "Asisten",
          sortable: true,
        },
        {
          key: "anesteshia",
          label: "Anastesi",
          sortable: true,
        },
        {
          key: "anesthetist",
          label: "Dokter Anastesi",
          sortable: true,
        },
        {
          key: "medicines",
          label: "Obat/Alkes",
          sortable: true,
        },
      ],
      // Table Items
      items: [],
      datas:"",
      isPackagePrescription: false,
    };
  },

  methods: {
    formaterDate(val) {
      if (val.length == 4) {
        this.form.date = this.form.date + "-";
      }
      if (val.length == 7) {
        this.form.date = this.form.date + "-";
      }
    },
    
    async pagination(id) {
      let filterParams = ``;
      let response = await module.paginate(
        "medical-actions/get-by-patient/" + id,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        let date = new Date(response.data[a].date)
        let convert = date.toLocaleString().split(",")[0]
        response.data[a].number = b + a;
        response.data[a].date = convert
        console.log(response.data[a].date)
      }

      this.items = response.data;
      // console.log("====== medical by patient", response);
      this.$bvModal.hide("modal-patient");
    },

    setPatient(value) {
      this.form.patient_id = value.id;
      this.display.patient_name = value.name;
      this.pagination(value.id);
    },

    async setPrescription(value) {
      // if(this.isPackagePrescription == false){
        console.log("tangkap", value);
        if (this.coll_medicine.length > 0) {
          let a;
          for (a = 0; a < this.coll_medicine.length; a++) {
            if (this.coll_medicine[a].id == value.id) {
              this.coll_medicine.splice(a, 1);
              if (this.coll_medicine.length == 0) {
                this.display.medicine_name = "";
                break;
              } else if (this.coll_medicine.length > 0) {
                this.displayMedicineName(this.coll_medicine);
                break;
              }
              break;
            } else if (a == this.coll_medicine.length - 1) {
              this.$bvModal.hide("modal-medicine");
              console.log("value", value);
              await this.medicine_id;
              this.medicine_id = value.id;
              console.log("medicine_id", this.medicine_id);
              // this.coll_medicine_form.id = "";
              this.coll_medicine_form.name = "";
              this.coll_medicine_form.sales_price = "";
              this.coll_medicine_form.quantity = "";
              this.setMedicine(value.id);
            }
          }
        } else {
          this.$bvModal.hide("modal-medicine");
          console.log("value", value);
          await this.medicine_id;
          this.medicine_id = value.id;
          console.log("medicine_id", this.medicine_id);
          // this.coll_medicine_form.id = "";
          this.coll_medicine_form.name = "";
          this.coll_medicine_form.sales_price = "";
          this.coll_medicine_form.quantity = "";
          this.setMedicine(value.id);
        }
      // }
    },

    async setPrescriptionOrder(value) {
      // if(this.isPackagePrescription == false){
        console.log("ini woiii");
        console.log("tangkap", value);
        if (this.coll_medicine_order.length > 0) {
          let a;
          for (a = 0; a < this.coll_medicine_order.length; a++) {
            if (this.coll_medicine_order[a].id == value.id) {
              this.coll_medicine_order.splice(a, 1);
              if (this.coll_medicine_order.length == 0) {
                this.display.medicine_order = "";
                break;
              } else if (this.coll_medicine_order.length > 0) {
                this.displayMedicineNameTakeaway(this.coll_medicine_order);
                break;
              }
              break;
            } else if (a == this.coll_medicine_order.length - 1) {
              this.$bvModal.hide("modal-medicine-order");

              await this.medicine_order_id;
              this.medicine_order_id = value.id;
              // this.coll_medicine_order_form.id = "";
              this.coll_medicine_order_form.name = "";
              this.coll_medicine_order_form.sales_price = "";
              this.coll_medicine_order_form.quantity = "";
              this.setMedicineTakeaway(value.id);
            }
          }
        } else {
          this.$bvModal.hide("modal-medicine-order");
          await this.medicine_order_id;
          this.medicine_order_id = value.id;

          // this.coll_medicine_form.id = "";
          this.coll_medicine_order_form.name = "";
          this.coll_medicine_order_form.sales_price = "";
          this.coll_medicine_order_form.quantity = "";
          this.setMedicineTakeaway(value.id);
        }
      // }
    },

    async setPackagePrescription(datas) {
      this.isPackagePrescription = true
      console.log("tangkap", datas);
      this.datas = datas
      console.log(this.datas);
      this.displayMedicineName(datas)

      this.coll_medicine = datas

      this.coll_medicine.forEach((el,index) => {
        this.coll_medicine[index].is_order = false
      });
      
        console.log("================== ASOOYYY JALAN", this.coll_medicine_form);
      // let b,arr = []
      // for(b=0;b<datas.length;b++){

      //   console.log("iniii dump",datas[b],datas.length,b);
        

      //   this.coll_medicine_form.name = datas[b].name;
      //   this.coll_medicine_form.id = datas[b].id;
      //   this.coll_medicine_form.sales_price = datas[b].sales_price;
      //   this.coll_medicine_form.total_price = datas[b].total_price;
      //   this.coll_medicine_form.quantity = datas[b].quantity;

      //   // this.coll_medicine.push(this.coll_medicine_form)
      //   arr.push( this.coll_medicine_form)
      // }
       
      
    },

    async setPackagePrescriptionOrder(datas) {
      this.isPackagePrescription = true
      console.log("tangkap", datas);
      this.datas = datas
      console.log(this.datas);
      this.displayMedicineNameTakeaway(datas)

      this.coll_medicine_order = datas

      this.coll_medicine_order.forEach((el,index) => {
        this.coll_medicine_order[index].is_order = true
      });

      console.log(this.coll_medicine_order);
      
        console.log("================== ASOOYYY JALAN", this.coll_medicine_order_form);
      // let b,arr = []
      // for(b=0;b<datas.length;b++){

      //   console.log("iniii dump",datas[b],datas.length,b);
        

      //   this.coll_medicine_form.name = datas[b].name;
      //   this.coll_medicine_form.id = datas[b].id;
      //   this.coll_medicine_form.sales_price = datas[b].sales_price;
      //   this.coll_medicine_form.total_price = datas[b].total_price;
      //   this.coll_medicine_form.quantity = datas[b].quantity;

      //   // this.coll_medicine.push(this.coll_medicine_form)
      //   arr.push( this.coll_medicine_form)
      // }
       
      
    },

    setMedicine(id) {
      ApiService.get("medicines/" + id).then((response) => {
        console.log("medicines", response.data.data);
        this.medicine = response.data.data;
        this.name_medicine = response.data.data.name;
        this.coll_medicine_form.name = response.data.data.name;
        this.coll_medicine_form.id = response.data.data.id;
        this.coll_medicine_form.sales_price = response.data.data.sales_price;
        this.coll_medicine_form.is_order = false;
        this.$refs["my-modal"].show();
      });
    },

    saveQtyInventory() {

      if(this.inventory_form.quantity == null){
        this.inventory_form.quantity = 0
      }

      this.inventory_form.quantity = this.inventory_form.quantity.replace(",",".")

      let exist = this.coll_inventory.findIndex(
        (x) => x.id == this.inventory_form.id
      );

      if (exist == -1) {
        let clone = { ...this.inventory_form };
        this.coll_inventory.push(clone);
      }

      this.inventory_form.id = "";
      this.inventory_form.name = "";
      this.inventory_form.price = 0;
      this.inventory_form.quantity = null;

      console.log(this.coll_inventory,"====================== ini");

      this.$bvModal.hide("modal-qty-inventory");
    },

    hideModalCancelInventory() {
      this.inventory_form.id = "";
      this.inventory_form.name = "";
      this.inventory_form.price = 0;
      this.inventory_form.quantity = null;
      this.inventory_form.uom = "";
      this.$bvModal.hide("modal-qty-inventory");
    },

    setMedicineTakeaway(id) {
      ApiService.get("medicines/" + id).then((response) => {
        console.log("medicines", response.data.data);
        this.medicine_order = response.data.data;
        this.name_medicine_order = response.data.data.name;
        this.coll_medicine_order_form.name = response.data.data.name;
        this.coll_medicine_order_form.id = response.data.data.id;
        this.coll_medicine_order_form.sales_price = response.data.data.sales_price;
        this.coll_medicine_order_form.is_order = true;
        this.$refs["my-modal-takeaway"].show();
      });
    },

    displayMedicineName(medicine) {
      if (medicine.length == 0) {
        this.display.medicine_name = "";
      } else {
        let str = "";
        medicine.forEach(function (value, index) {
          str += value.name;
          if (index != medicine.length - 1) {
            if (medicine.length > 1) str += ", ";
          }
        });
        this.display.medicine_name = str;
      }
    },

    displayMedicineNameTakeaway(medicine) {
      if (medicine.length == 0) {
        this.display.medicine_order = "";
      } else {
        let str = "";
        medicine.forEach(function (value, index) {
          str += value.name;
          if (index != medicine.length - 1) {
            if (medicine.length > 1) str += ", ";
          }
        });
        this.display.medicine_order = str;

        console.log(this.display.medicine_order,"ini gaynnnnnn");
      }
    },

     // inventory
    async setInventory(value, data) {

      
      this.inventory_form.id = value.id;
      this.inventory_form.name = value.name;
      this.inventory_form.price = value.sales_price;
      this.inventory_form.stock = value.stock;
      this.inventory_form.uom = value.uom;
      console.log(this.inventory_form,"valueeeee");
      
      this.$bvModal.show("modal-qty-inventory");
    },

    medicineFormOnsubmit() {
      console.log("quantity", parseInt(this.coll_medicine_form.quantity));
      console.log("sales_price", parseInt(this.coll_medicine_form.sales_price));
      this.coll_medicine_form.total_price =
        parseInt(this.coll_medicine_form.quantity) *
        parseInt(this.coll_medicine_form.sales_price);
      this.coll_medicine_form.total_price = parseInt(
        this.coll_medicine_form.total_price
      ).toLocaleString("id-ID");
      this.coll_medicine_form.sales_price = parseInt(
        this.coll_medicine_form.sales_price
      ).toLocaleString("id-ID");

      this.medicine_collection_submit = true;
      let clone = { ...this.coll_medicine_form };
      this.coll_medicine.push(clone);

      console.log("hasil", this.coll_medicine);
      this.displayMedicineName(this.coll_medicine);
      this.hideModal();
    },
    
    medicineOrderFormOnsubmit() {
      
      this.coll_medicine_order_form.total_price =
        parseInt(this.coll_medicine_order_form.quantity) *
        parseInt(this.coll_medicine_order_form.sales_price);
      this.coll_medicine_order_form.total_price = parseInt(
        this.coll_medicine_order_form.total_price
      ).toLocaleString("id-ID");
      this.coll_medicine_order_form.sales_price = parseInt(
        this.coll_medicine_order_form.sales_price
      ).toLocaleString("id-ID");

      this.medicine_collection_order_submit = true;
      let clone = { ...this.coll_medicine_order_form };
      this.coll_medicine_order.push(clone);

      console.log("hasil", this.coll_medicine);
      this.displayMedicineNameTakeaway(this.coll_medicine_order);
      this.hideModalTakeaway();
    },

    hideModal() {
      this.$refs["my-modal"].hide();
    },
    
    hideModalTakeaway() {
      this.$refs["my-modal-takeaway"].hide();
    },

    async hideModalCancel() {
      if (this.medicine_collection_submit == false) {
        console.log("tidak tekan submit");
        // this.medicine_id.pop();
      }
      await this.$refs["my-modal"].hide();
      this.setMedicineSubmit();
    },

    async hideModalCancelTakeaway() {
      if (this.medicine_collection_order_submit == false) {
        console.log("tidak tekan submit");
        // this.medicine_id.pop();
      }
      await this.$refs["my-modal-takeaway"].hide();
      this.setMedicineOrderSubmit();
    },

    setMedicineSubmit() {
      this.medicine_collection_submit = false;
    },
    
    setMedicineOrderSubmit() {
      this.medicine_collection_order_submit = false;
    },

    setAssistant(value) {
      if (this.coll_assistant.length > 0) {
        let a;
        for (a = 0; a < this.coll_assistant.length > 0; a++) {
          if (this.coll_assistant[a].id == value.id) {
            this.coll_assistant.splice(a, 1);
            console.log("splice");
            if (this.coll_assistant.length == 0) {
              this.display.assistant_name = "";
              break;
            } else if (this.coll_assistant.length > 0) {
              this.displayAssistanName(this.coll_assistant);
              break;
            }
            break;
          } else if (a == this.coll_assistant.length - 1) {
            this.display.assistant_name = value.name;
            this.coll_assistant_form.id = value.id;
            this.coll_assistant_form.name = value.name;
            this.coll_assistant_form.position = value.position;

            let clone = { ...this.coll_assistant_form };
            this.coll_assistant.push(clone);
            console.log("coll_assistant", this.coll_assistant);
            this.displayAssistanName(this.coll_assistant);
            break;
          }
        }
      } else {
        this.display.assistant_name = value.name;
        this.coll_assistant_form.id = value.id;
        this.coll_assistant_form.name = value.name;
        this.coll_assistant_form.position = value.position;

        let clone = { ...this.coll_assistant_form };
        this.coll_assistant.push(clone);
        console.log("coll_assistant", this.coll_assistant);
        this.displayAssistanName(this.coll_assistant);
      }
    },

    setOmploop(value) {
      if (this.coll_omploop.length > 0) {
        let a;
        for (a = 0; a < this.coll_omploop.length > 0; a++) {
          if (this.coll_omploop[a].id == value.id) {
            this.coll_omploop.splice(a, 1);
            console.log("splice");
            if (this.coll_omploop.length == 0) {
              this.display.omploop_name = "";
              break;
            } else if (this.coll_omploop.length > 0) {
              this.displayOmploopName(this.coll_omploop);
              break;
            }
            break;
          } else if (a == this.coll_omploop.length - 1) {
            this.display.omploop_name = value.name;
            this.coll_omploop_form.id = value.id;
            this.coll_omploop_form.name = value.name;
            this.coll_omploop_form.position = value.position;

            let clone = { ...this.coll_omploop_form };
            this.coll_omploop.push(clone);
            console.log("coll_assistant", this.coll_omploop);
            this.displayOmploopName(this.coll_omploop);
            break;
          }
        }
      } else {
        this.display.omploop_name = value.name;
        this.coll_omploop_form.id = value.id;
        this.coll_omploop_form.name = value.name;
        this.coll_omploop_form.position = value.position;

        let clone = { ...this.coll_omploop_form };
        this.coll_omploop.push(clone);
        console.log("coll_assistant", this.coll_omploop);
        this.displayOmploopName(this.coll_omploop);
      }
      console.log("omploop strrrrr",this.coll_omploop_str);
    },

    setNurseAction(value) {
      if (this.coll_nurse_action.length > 0) {
        let a;
        for (a = 0; a < this.coll_nurse_action.length > 0; a++) {
          if (this.coll_nurse_action[a].id == value.id) {
            this.coll_nurse_action.splice(a, 1);
            console.log("splice");
            if (this.coll_nurse_action.length == 0) {
              this.display.nurse_action_name = "";
              break;
            } else if (this.coll_nurse_action.length > 0) {
              this.displayNurseActionName(this.coll_nurse_action);
              break;
            }
            break;
          } else if (a == this.coll_nurse_action.length - 1) {
            this.display.nurse_action_name = value.name;
            this.coll_nurse_action_form.id = value.id;
            this.coll_nurse_action_form.name = value.name;
            this.coll_nurse_action_form.position = value.position;

            let clone = { ...this.coll_nurse_action_form };
            this.coll_nurse_action.push(clone);
            console.log("coll_assistant", this.coll_nurse_action);
            this.displayNurseActionName(this.coll_nurse_action);
            break;
          }
        }
      } else {
        this.display.nurse_action_name = value.name;
        this.coll_nurse_action_form.id = value.id;
        this.coll_nurse_action_form.name = value.name;
        this.coll_nurse_action_form.position = value.position;

        let clone = { ...this.coll_nurse_action_form };
        this.coll_nurse_action.push(clone);
        console.log("coll_assistant", this.coll_nurse_action);
        this.displayNurseActionName(this.coll_nurse_action);
      }
      console.log("nurse_action strrrrr",this.coll_nurse_action_str);
    },

    resetAssistant() {
      this.coll_assistant.splice(0, this.coll_assistant.length);
      console.log("reset", this.coll_assistant);
      this.display.assistant_name = "";
    },

    resetOmploop() {
      this.coll_omploop.splice(0, this.coll_omploop.length);
      console.log("reset", this.coll_omploop);
      this.display.omploop_name = "";
    },
    resetNurseAction() {
      this.coll_nurse_action.splice(0, this.coll_nurse_action.length);
      console.log("reset", this.coll_nurse_action);
      this.display.nurse_action_name = "";
    },

    displayAssistanName(assistant) {
      if (assistant.length == 0) {
        this.display.assistant_name = "";
      } else {
        let str = "";
        assistant.forEach(function (value, index) {
          console.log("display assistant");
          str += value.name + `(${value.position})`;
          if (index != assistant.length - 1) {
            if (assistant.length > 1) str += ", ";
          }
        });
        this.display.assistant_name = str;
      }
    },
    
    displayOmploopName(omploop) {
      console.log(omploop);
      if (omploop.length == 0) {
        this.display.omploop_name = "";
      } else {
        let str = "";
        omploop.forEach(function (value, index) {
          // console.log("display assistant");
          str += value.name + `(${value.position})`;
          if (index != omploop.length - 1) {
            if (omploop.length > 1) str += ", ";
          }
        });
        this.display.omploop_name = str;
      }
    },

    displayNurseActionName(nurse_action) {
      console.log(nurse_action);
      if (nurse_action.length == 0) {
        this.display.nurse_action_name = "";
      } else {
        let str = "";
        nurse_action.forEach(function (value, index) {
          // console.log("display assistant");
          str += value.name + `(${value.position})`;
          if (index != nurse_action.length - 1) {
            if (nurse_action.length > 1) str += ", ";
          }
        });
        this.display.nurse_action_name = str;
      }
    },

    // setMedicine(value) {
    //   let a;
    //   if (this.coll_medicine.length > 0) {
    //     for (a = 0; a < this.coll_medicine.length; a++) {
    //       console.log("a", a);
    //       console.log("length", this.coll_medicine.length);
    //       if (this.coll_medicine[a].id == value.id) {
    //         console.log("id coll sudah ada", this.coll_medicine[a].id);
    //         console.log("id value sudah ada", value.id);
    //         Swal.fire(
    //           "Gagal",
    //           "Obat dan Alkes tersebut telah dipilih",
    //           "warning"
    //         );
    //         break;
    //       } else if (a == this.coll_medicine.length - 1) {
    //         // this.$bvModal.hide("modal-medicine");

    //         this.display.medicine_name = value.name;
    //         this.coll_medicine.id = value.id;
    //         this.coll_medicine.name = value.name;
    //         this.coll_medicine.sales_price = value.sales_price;

    //         let clone = { ...this.coll_medicine };
    //         this.coll_medicine.push(clone);
    //         console.log("coll_medicine", this.coll_medicine);
    //         this.displayMedicineName(this.coll_medicine);
    //         break;
    //       }
    //     }
    //   } else {
    //     this.display.medicine_name = value.name;
    //     this.coll_medicine.id = value.id;
    //     this.coll_medicine.name = value.name;
    //     this.coll_medicine.sales_price = value.sales_price;

    //     let clone = { ...this.coll_medicine };
    //     this.coll_medicine.push(clone);
    //     console.log("coll_medicine", this.coll_medicine);

    //     this.displayMedicineName(this.coll_medicine);

    //     // this.$bvModal.hide("modal-medicine");
    //   }
    // },

    resetMedicine() {
      this.coll_medicine.splice(0, this.coll_medicine.length);
      console.log("reset", this.coll_medicine);
      this.display.medicine_name = "";
    },

    resetMedicineTakeaway() {
      this.coll_medicine_order.splice(0, this.coll_medicine_order.length);
      console.log("reset", this.coll_medicine_order);
      this.display.medicine_order = "";
    },

    removeMedicine($id) {
      console.log("remove batch", $id);
      let a;
      for (a = 0; a < this.coll_medicine.length; a++) {
        if (this.coll_medicine[a].id == $id) {
          this.coll_medicine.splice(a, 1);
          console.log("hasil", this.coll_medicine);
        }
      }
      this.displayMedicineName(this.coll_medicine);
    },
    
    removeMedicineTakeaway($id) {
      let a;
      for (a = 0; a < this.coll_medicine_order.length; a++) {
        if (this.coll_medicine_order[a].id == $id) {
          this.coll_medicine_order.splice(a, 1);
        }
      }
      this.displayMedicineNameTakeaway(this.coll_medicine_order);
    },
    
    removeInventory($id) {
      let a;
      for (a = 0; a < this.coll_inventory.length; a++) {
        if (this.coll_inventory[a].id == $id) {
          this.coll_inventory.splice(a, 1);
        }
      }
      // this.displayMedicineNameTakeaway(this.coll_medicine_order);
    },

    // displayMedicineName(medicine) {
    //   if (medicine.length == 0) {
    //     this.display.medicine_name = "";
    //   } else {
    //     let str = "";
    //     medicine.forEach(function (value, index) {
    //       str += value.name;
    //       if (index != medicine.length - 1) {
    //         if (medicine.length > 1) str += ", ";
    //       }
    //     });
    //     this.display.medicine_name = str;
    //   }
    // },

    onFileUpload(evt) {
      this.uploadAttachmentURL = URL.createObjectURL(evt.target.files[0]);
      console.log(evt);
      this.form.attachment = evt.target.files[0];
      console.log("attachment", this.form.attachment);
    },

    upload(event) {
      if (event.target.files.length > 0) {
        this.formData = new FormData();
      }

      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("attachment[]", event.target.files[a]);
      }
    },

    async getDoctorOption() {
      let response = await module.setDoctorTreeSelect("doctors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
      }
    },

    async getActionTypes() {
      let response = await module.setTreeSelect("payment-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.action_types = response.data;
      }
    },

    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      console.log("umur", age);
      this.patient_age = age;
      // return age;
    },

    setOperator(evt) {
      console.log("evt", evt);
      this.coll_operator_form.id = evt.id;
      this.coll_operator_form.name = evt.label;

      let clone = { ...this.coll_operator_form };
      this.coll_operator.push(clone);
      console.log("coll_operator", this.coll_operator);
    },

    removeOperator(event) {
      console.log(event);
      console.log(event.id);

      let a;
      for (a = 0; a < this.coll_operator.length; a++) {
        if (event.id == this.coll_operator[a].id) {
          console.log("hapus");
          this.coll_operator.splice(a, 1);
        }
      }
    },

    setResponsibleDoctor(evt) {
      console.log("evt", evt);
      this.coll_responsibleDoctor_form.id = evt.id;
      this.coll_responsibleDoctor_form.name = evt.label;
      
      let clone = { ...this.coll_responsibleDoctor_form };
      this.coll_responsibleDoctor.push(clone);
      console.log("coll_responsible doctor", this.coll_responsibleDoctor);
    },

    removeResponsibleDoctor(event) {
      console.log(event);
      console.log(event.id);

      let a;
      for (a = 0; a < this.coll_responsibleDoctor.length; a++) {
        if (event.id == this.coll_responsibleDoctor[a].id) {
          console.log("hapus");
          this.coll_responsibleDoctor.splice(a, 1);
        }
      }
    },

    setAnesthetist(evt) {
      console.log("evt", evt);
      this.coll_anesthetist_form.id = evt.id;
      this.coll_anesthetist_form.name = evt.label;

      let clone = { ...this.coll_anesthetist_form };
      this.coll_anesthetist.push(clone);
      console.log("coll_anesthetist", this.coll_anesthetist);
    },

    removeAnesthetist(event) {
      console.log(event);
      console.log(event.id);

      let a;
      for (a = 0; a < this.coll_anesthetist.length; a++) {
        if (event.id == this.coll_anesthetist[a].id) {
          console.log("hapus");
          this.coll_anesthetist.splice(a, 1);
        }
      }
    },

    setActionType(evt) {
      console.log("evt", evt);
      this.coll_action_type_form.id = evt.id;
      this.coll_action_type_form.name = evt.label;

      let clone = { ...this.coll_action_type_form };
      this.coll_action_type.push(clone);
      console.log("coll_action_type", this.coll_action_type);
    },

    removeActionType(event) {
      console.log(event);
      console.log(event.id);

      let a;
      for (a = 0; a < this.coll_action_type.length; a++) {
        if (event.id == this.coll_action_type[a].id) {
          console.log("hapus");
          this.coll_action_type.splice(a, 1);
        }
      }
    },

    async formOnsubmit() {
      this.coll_operator_str = await JSON.stringify(this.coll_operator);
      this.form.operator = await this.coll_operator_str;
      
      this.coll_responsibleDoctor_str = await JSON.stringify(this.coll_responsibleDoctor);
      this.form.responsible_doctor = await this.coll_responsibleDoctor_str;

      this.coll_anesthetist_str = await JSON.stringify(this.coll_anesthetist);
      this.form.anesthetist = await this.coll_anesthetist_str;

      this.coll_action_type_str = await JSON.stringify(this.coll_action_type);
      this.form.action_type = await this.coll_action_type_str;

      this.coll_assistant_str = await JSON.stringify(this.coll_assistant);
      this.form.assistant = await this.coll_assistant_str;
      
      this.coll_omploop_str = await JSON.stringify(this.coll_omploop);
      this.form.omploop = await this.coll_omploop_str;

      this.coll_nurse_action_str = await JSON.stringify(this.coll_nurse_action);
      this.form.nurse_action = await this.coll_nurse_action_str;
      
      this.coll_inventory_str = await JSON.stringify(this.coll_inventory);
      this.form.inventory = await this.coll_inventory_str;

      let medicine = this.coll_medicine.concat(this.coll_medicine_order)
      console.log(medicine,"medicccccc");
      // this.coll_medicine_str = await JSON.stringify(this.coll_medicine);
      // this.coll_medicine_order_str = await JSON.stringify(this.coll_medicine_order);
      this.form.medicines = await JSON.stringify(medicine)
      

      for (const [key, value] of Object.entries(this.form)) {
        this.formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(this.formData, this.route);

      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        if(response.success.title == "Berhasil"){
          Swal.fire(response.success.title, response.success.message, "success");
          this.$router.push("/medical-actions");
        }else if(response.success.title == "Gagal"){
          Swal.fire(response.success.title, response.success.message, "error");
        }else{
          Swal.fire(response.success.title, response.success.message, "success");
          this.$router.push("/medical-actions");
        }
      }
    },
  },

  mounted() {
    this.getDoctorOption();
    this.getActionTypes();
  },

  watch: {
    "form.patient_name": function (newVal, oldVal) {
      this.display.patient_name = newVal;
    },
  },
};
</script>

<style scoped>
.nowrap {
  white-space: normal;
}

@media (max-width: 767.98px) {
  .nowrap {
    white-space: nowrap;
  }
}
</style>